<script>
import { mapActions, mapGetters } from 'vuex';
import UserModal from '@/views/dashboard/components/modals/UserModal';
// import helper from '@/plugins/helper';
import PAPER_SHARES_STATUSES from '@/constants/paper_statuses';

export default {
    name: 'PaperShares',

    components: { UserModal },

    data: () => ({
        PAPER_SHARES_STATUSES: PAPER_SHARES_STATUSES,
        open_modal_delivery: false,
        menu: false,
        print_dialog: false,

        loading: false,

        delivery: {
            address: '',
            city: '',
            zip_code: '',
            citizenship: '',
        },

        filter: {
            date: [],
            certificate_id: '',
            email: '',
            count: '',
        },
    }),
    methods: {
        ...mapActions('paperShares', [
            'fetchPaperShares',
            'getPaperShareCertificate',
            'getAllCertificatesExcel',
        ]),
        ...mapActions('user_modal', ['fetchUserInfo']),

        async paginationTable(event) {
            this.loading = true;

            const { itemsPerPage, page } = event;

            const payload = {
                rows: itemsPerPage,
                pagination: page,
            };

            await this.fetchPaperShares(payload);
            this.loading = false;
        },

        async downloadPaperCertificatesExcel() {
            await this.getAllCertificatesExcel().then(res => {
                const url = window.URL.createObjectURL(new Blob([res]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'all-paper-certificates.xlsx');
                document.body.appendChild(link);
                link.click();
            });
        },

        async getPaperCertificate(id, background) {
            this.print_dialog = true;
            if (background) {
                await this.getPaperShareCertificate({
                    certificate_id: id,
                }).then(res => {
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(res);
                    window.open(link.href);
                });
            } else {
                await this.getPaperShareCertificate({
                    certificate_id: id,
                    background,
                }).then(res => {
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(res);
                    window.open(link.href);
                });
            }
        },

        resetDate() {
            this.filter.date = [];
            this.menu = false;
        },

        dataDayYear(value) {
            const time = +value;
            if (!time || typeof time === 'string') return '0';
            const formatter = new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            });
            return formatter.format(new Date(+time * 1000));
        },

        openModalDelivery({
            address_delivery,
            citizenship_delivery,
            city_delivery,
            zip_code_delivery,
        }) {
            this.open_modal_delivery = true;
            this.delivery.address = address_delivery;
            this.delivery.citizenship = citizenship_delivery;
            this.delivery.city = city_delivery;
            this.delivery.zip_code = zip_code_delivery;
        },

        dataTime(value) {
            const time = +value;
            if (!time || typeof time === 'string') return '0';
            const formatter = new Intl.DateTimeFormat('en-AU', {
                timeStyle: 'medium',
                hour12: false,
            });
            return formatter.format(new Date(+time * 1000));
        },

        getColorByStatus(status) {
            let color;
            switch (parseInt(status)) {
                case PAPER_SHARES_STATUSES.FORMED:
                    color = 'orange';
                    break;
                case PAPER_SHARES_STATUSES.PENDING_PAYMENT:
                    color = 'orange';
                    break;
                case PAPER_SHARES_STATUSES.DELIVERY_PAID:
                    color = 'green';
                    break;
                default:
                    color = null;
                    break;
            }
            return color;
        },
    },

    computed: {
        ...mapGetters('paperShares', {
            paper: 'getPaperShares',
            countPaper: 'getCountPaperShares',
        }),

        headers() {
            return [
                {
                    text: 'Date of create',
                    value: 'created_at',
                    // filter: (value, search, item) => {
                    //     const THREE_HOURS_IN_SECONDS = 3 * 3600;
                    //     const DAY_IN_SECONDS = 86399;

                    //     if (this.filter.date.length === 2) {
                    //         let startDateTimestamp = helper.toTimestamp(
                    //             this.filter.date['0'],
                    //         );
                    //         let endDateTimestamp = helper.toTimestamp(
                    //             this.filter.date['1'],
                    //         );

                    //         if (startDateTimestamp > endDateTimestamp) {
                    //             [startDateTimestamp, endDateTimestamp] = [
                    //                 endDateTimestamp,
                    //                 startDateTimestamp,
                    //             ];
                    //         }
                    //         return (
                    //             startDateTimestamp - THREE_HOURS_IN_SECONDS <=
                    //                 item.created_at &&
                    //             endDateTimestamp +
                    //                 DAY_IN_SECONDS -
                    //                 THREE_HOURS_IN_SECONDS >=
                    //                 item.created_at
                    //         );
                    //     }

                    //     if (this.filter.date.length === 1) {
                    //         let startDateTimestamp = helper.toTimestamp(
                    //             this.filter.date['0'],
                    //         );
                    //         let endDateTimestamp =
                    //             startDateTimestamp + DAY_IN_SECONDS;

                    //         return (
                    //             startDateTimestamp - THREE_HOURS_IN_SECONDS <=
                    //                 item.created_at &&
                    //             endDateTimestamp - THREE_HOURS_IN_SECONDS >=
                    //                 item.created_at
                    //         );
                    //     }

                    //     return true;
                    // },
                    align: 'center',
                    sortable: false,
                },
                {
                    text: 'Certificate ID',
                    value: 'certificate_id',
                    // filter: value => {
                    //     console.log(value);
                    //     return (value + '')
                    //         .toLowerCase()
                    //         .includes(this.filter.certificate_id.toLowerCase());
                    // },
                    align: 'center',
                    sortable: false,
                },
                {
                    text: 'Email',
                    value: 'mail',
                    // filter: value => {
                    //     return (value + '')
                    //         .toLowerCase()
                    //         .includes(this.filter.email.toLowerCase());
                    // },
                    align: 'center',
                    sortable: false,
                },
                {
                    text: 'Quantity shares',
                    value: 'count',
                    // filter: value => {
                    //     return (value + '')
                    //         .toLowerCase()
                    //         .includes(this.filter.count.toLowerCase());
                    // },
                    align: 'center',
                    sortable: false,
                },
                {
                    text: 'Status',
                    value: 'print',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: 'PDF View',
                    value: 'action',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: 'Delivery Address',
                    value: 'delivery',
                    align: 'center',
                    sortable: false,
                },
            ];
        },
    },
};
</script>

<style scoped>
.startup__actions {
    top: -60px;
    right: 5%;
    z-index: 1;
    position: absolute;
}

.btnDownload {
    cursor: pointer;
}
</style>

<template>
    <div>
        <div class="startup__actions">
            <v-btn
                class="btnDownload"
                color="#47a4db"
                @click="downloadPaperCertificatesExcel"
            >
                Download Excel
            </v-btn>
        </div>
        <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            @update:options="paginationTable($event)"
            dense
            :headers="headers"
            :items="loading ? [] : paper"
            :items-per-page="15"
            :footer-props="{
                'items-per-page-options': [15, 50, 100, 150, 200],
            }"
            :server-items-length="countPaper"
        >
            <template v-slot:[`item.created_at`]="{ item }">
                <span style="margin-right: 10px">
                    {{ dataDayYear(item.created_at) }}
                </span>
                {{ dataTime(item.created_at) }}
            </template>

            <template v-slot:[`item.mail`]="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <a
                            v-on="on"
                            v-bind="attrs"
                            class="blue--text"
                            @click.prevent="fetchUserInfo(item.user_id)"
                            >{{ item.mail }}</a
                        >
                    </template>
                    <span>User id {{ item.user_id }}</span>
                </v-tooltip>
            </template>

            <template v-slot:[`item.print`]="{ item }">
                <v-tooltip
                    bottom
                    v-if="parseInt(item.print) === PAPER_SHARES_STATUSES.FORMED"
                    max-width="400px"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-chip
                            style="cursor: pointer"
                            v-on="on"
                            v-bind="attrs"
                            small
                            dark
                            :color="getColorByStatus(item.print)"
                        >
                            Formed
                        </v-chip>
                    </template>
                    <span>Formed status</span>
                </v-tooltip>
                <v-tooltip
                    bottom
                    v-if="
                        parseInt(item.print) ===
                            PAPER_SHARES_STATUSES.PENDING_PAYMENT
                    "
                    max-width="400px"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-chip
                            style="cursor: pointer"
                            v-on="on"
                            v-bind="attrs"
                            small
                            dark
                            :color="getColorByStatus(item.print)"
                        >
                            Pending Payment
                        </v-chip>
                    </template>
                    <span>Pending Payment status</span>
                </v-tooltip>
                <v-tooltip
                    bottom
                    v-if="
                        parseInt(item.print) ===
                            PAPER_SHARES_STATUSES.DELIVERY_PAID
                    "
                    max-width="400px"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-chip
                            style="cursor: pointer"
                            v-on="on"
                            v-bind="attrs"
                            small
                            dark
                            :color="getColorByStatus(item.print)"
                        >
                            Delivery paid
                        </v-chip>
                    </template>
                    <span>Delivery paid status</span>
                </v-tooltip>
            </template>

            <template v-slot:[`item.action`]="{ item }">
                <div style="display: flex; justify-content: center">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="success"
                                v-on="on"
                                v-bind="attrs"
                                text
                                icon
                                @click="
                                    getPaperCertificate(
                                        item.certificate_id,
                                        true,
                                    )
                                "
                                style="height: 24px"
                            >
                                <v-icon>mdi-printer</v-icon>
                            </v-btn>
                        </template>
                        <span>Show PDF certificate?</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-on="on"
                                v-bind="attrs"
                                text
                                icon
                                @click="
                                    getPaperCertificate(
                                        item.certificate_id,
                                        false,
                                    )
                                "
                                style="height: 24px"
                            >
                                <v-icon>mdi-printer</v-icon>
                            </v-btn>
                        </template>
                        <span>Show PDF certificate?</span>
                    </v-tooltip>
                </div>
            </template>

            <template v-slot:[`item.delivery`]="{ item }">
                <div style="display: flex; justify-content: center;">
                    <v-tooltip
                        bottom
                        v-if="
                            item.print !== PAPER_SHARES_STATUSES.FORMED &&
                                item.print !==
                                    PAPER_SHARES_STATUSES.PENDING_PAYMENT
                        "
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="success"
                                v-on="on"
                                v-bind="attrs"
                                text
                                @click="openModalDelivery(item)"
                                style="height: 24px"
                            >
                                Delivery address
                            </v-btn>
                        </template>
                        <span>Show delivery address?</span>
                    </v-tooltip>
                    <v-tooltip bottom v-else>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="success"
                                v-on="on"
                                v-bind="attrs"
                                text
                                disabled
                                style="height: 24px"
                            >
                                Delivery address
                            </v-btn>
                        </template>
                        <span>Show delivery address?</span>
                    </v-tooltip>
                </div>
            </template>

            <!-- <template v-slot:[`body.prepend`]>
                <tr>
                    <td>
                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="filter.date"
                                    label="Date range"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="filter.date" no-title range>
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="resetDate">
                                    Clear
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="menu = false"
                                >
                                    OK
                                </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </td>

                    <td>
                        <v-text-field
                            v-model="filter.certificate_id"
                        ></v-text-field>
                    </td>

                    <td>
                        <v-text-field v-model="filter.email"></v-text-field>
                    </td>

                    <td>
                        <v-text-field v-model="filter.count"></v-text-field>
                    </td>
                </tr>
            </template> -->
        </v-data-table>

        <v-dialog v-model="open_modal_delivery" persistent max-width="500">
            <v-card style="font-size: 16px;">
                <v-card-title class="headline">
                    Certificate Delivery Address
                </v-card-title>

                <v-col style="display: flex;">
                    <div>
                        Street Address: <b>{{ delivery.address }}</b>
                    </div>
                </v-col>
                <v-col style="display: flex;">
                    <div>
                        City: <b>{{ delivery.city }}</b>
                    </div>
                </v-col>
                <v-col style="display: flex;">
                    <div>
                        Post Code:
                        <b>{{ delivery.zip_code ? delivery.zip_code : '-' }}</b>
                    </div>
                </v-col>
                <v-col style="display: flex;">
                    <div>
                        Country: <b>{{ delivery.citizenship }}</b>
                    </div>
                </v-col>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="red darken-1"
                        text
                        @click="open_modal_delivery = false"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="open_modal_delivery = false"
                    >
                        OK
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <user-modal />
    </div>
</template>
