var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"startup__actions"},[_c('v-btn',{staticClass:"btnDownload",attrs:{"color":"#47a4db"},on:{"click":_vm.downloadPaperCertificatesExcel}},[_vm._v(" Download Excel ")])],1),_c('v-data-table',{attrs:{"loading":_vm.loading,"loading-text":"Loading... Please wait","dense":"","headers":_vm.headers,"items":_vm.loading ? [] : _vm.paper,"items-per-page":15,"footer-props":{
            'items-per-page-options': [15, 50, 100, 150, 200],
        },"server-items-length":_vm.countPaper},on:{"update:options":function($event){return _vm.paginationTable($event)}},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticStyle:{"margin-right":"10px"}},[_vm._v(" "+_vm._s(_vm.dataDayYear(item.created_at))+" ")]),_vm._v(" "+_vm._s(_vm.dataTime(item.created_at))+" ")]}},{key:"item.mail",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticClass:"blue--text",on:{"click":function($event){$event.preventDefault();return _vm.fetchUserInfo(item.user_id)}}},'a',attrs,false),on),[_vm._v(_vm._s(item.mail))])]}}],null,true)},[_c('span',[_vm._v("User id "+_vm._s(item.user_id))])])]}},{key:"item.print",fn:function(ref){
        var item = ref.item;
return [(parseInt(item.print) === _vm.PAPER_SHARES_STATUSES.FORMED)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"400px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticStyle:{"cursor":"pointer"},attrs:{"small":"","dark":"","color":_vm.getColorByStatus(item.print)}},'v-chip',attrs,false),on),[_vm._v(" Formed ")])]}}],null,true)},[_c('span',[_vm._v("Formed status")])]):_vm._e(),(
                    parseInt(item.print) ===
                        _vm.PAPER_SHARES_STATUSES.PENDING_PAYMENT
                )?_c('v-tooltip',{attrs:{"bottom":"","max-width":"400px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticStyle:{"cursor":"pointer"},attrs:{"small":"","dark":"","color":_vm.getColorByStatus(item.print)}},'v-chip',attrs,false),on),[_vm._v(" Pending Payment ")])]}}],null,true)},[_c('span',[_vm._v("Pending Payment status")])]):_vm._e(),(
                    parseInt(item.print) ===
                        _vm.PAPER_SHARES_STATUSES.DELIVERY_PAID
                )?_c('v-tooltip',{attrs:{"bottom":"","max-width":"400px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticStyle:{"cursor":"pointer"},attrs:{"small":"","dark":"","color":_vm.getColorByStatus(item.print)}},'v-chip',attrs,false),on),[_vm._v(" Delivery paid ")])]}}],null,true)},[_c('span',[_vm._v("Delivery paid status")])]):_vm._e()]}},{key:"item.action",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"height":"24px"},attrs:{"color":"success","text":"","icon":""},on:{"click":function($event){return _vm.getPaperCertificate(
                                    item.certificate_id,
                                    true
                                )}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-printer")])],1)]}}],null,true)},[_c('span',[_vm._v("Show PDF certificate?")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"height":"24px"},attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.getPaperCertificate(
                                    item.certificate_id,
                                    false
                                )}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-printer")])],1)]}}],null,true)},[_c('span',[_vm._v("Show PDF certificate?")])])],1)]}},{key:"item.delivery",fn:function(ref){
                                var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[(
                        item.print !== _vm.PAPER_SHARES_STATUSES.FORMED &&
                            item.print !==
                                _vm.PAPER_SHARES_STATUSES.PENDING_PAYMENT
                    )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"height":"24px"},attrs:{"color":"success","text":""},on:{"click":function($event){return _vm.openModalDelivery(item)}}},'v-btn',attrs,false),on),[_vm._v(" Delivery address ")])]}}],null,true)},[_c('span',[_vm._v("Show delivery address?")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"height":"24px"},attrs:{"color":"success","text":"","disabled":""}},'v-btn',attrs,false),on),[_vm._v(" Delivery address ")])]}}],null,true)},[_c('span',[_vm._v("Show delivery address?")])])],1)]}}],null,true)}),_c('v-dialog',{attrs:{"persistent":"","max-width":"500"},model:{value:(_vm.open_modal_delivery),callback:function ($$v) {_vm.open_modal_delivery=$$v},expression:"open_modal_delivery"}},[_c('v-card',{staticStyle:{"font-size":"16px"}},[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Certificate Delivery Address ")]),_c('v-col',{staticStyle:{"display":"flex"}},[_c('div',[_vm._v(" Street Address: "),_c('b',[_vm._v(_vm._s(_vm.delivery.address))])])]),_c('v-col',{staticStyle:{"display":"flex"}},[_c('div',[_vm._v(" City: "),_c('b',[_vm._v(_vm._s(_vm.delivery.city))])])]),_c('v-col',{staticStyle:{"display":"flex"}},[_c('div',[_vm._v(" Post Code: "),_c('b',[_vm._v(_vm._s(_vm.delivery.zip_code ? _vm.delivery.zip_code : '-'))])])]),_c('v-col',{staticStyle:{"display":"flex"}},[_c('div',[_vm._v(" Country: "),_c('b',[_vm._v(_vm._s(_vm.delivery.citizenship))])])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){_vm.open_modal_delivery = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.open_modal_delivery = false}}},[_vm._v(" OK ")])],1)],1)],1),_c('user-modal')],1)}
var staticRenderFns = []

export { render, staticRenderFns }